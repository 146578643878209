import { LoginFormComponent } from './login-form/login-form.component';
import { RouterModule, Routes } from '@angular/router';
import { NgModule, Component } from '@angular/core';

import { AutenticacaoPowerbiComponent } from './autenticacao-powerbi/autenticacao-powerbi.component';
import { PagesComponent } from './pages.component';
import { NotFoundComponent } from './not-found/not-found.component';
import { PaineisComponent } from './paineis/paineis.component';
import { AuthGuard } from '././auth.guard';
import { AddPainelComponent } from './add-painel/add-painel.component';
import { ListPainelComponent } from './list-painel/list-painel.component';
import { AddUserComponent } from './add-user/add-user.component';
import { ListUserComponent } from './list-user/list-user.component';
import { ListGrupoComponent } from './list-grupo/list-grupo.component';
import { AssociarGrupoComponent } from './associar-grupo/associar-grupo-painel.component';
import { ChangePasswordComponent } from './change-password/change-password.component';
import { CadGrupoComponent } from './cad-grupo/cad-grupo.component';

const routes: Routes = [{
  path: '',
  component: PagesComponent,
  children: [
    {
      path: 'autenticacao-powerbi',
      component: AutenticacaoPowerbiComponent,
      canActivate: [AuthGuard]
    },
    {
      path: 'paineis',
      component: PaineisComponent,
      canActivate: [AuthGuard]
    },
    {
      path: 'add-paineis',
      component: AddPainelComponent,
      canActivate: [AuthGuard]
    },
    {
      path: 'list-paineis',
      component: ListPainelComponent,
      canActivate: [AuthGuard]
    },
    {
      path: 'add-users',
      component: AddUserComponent,
      canActivate: [AuthGuard]
    },
    {
      path: 'list-users',
      component: ListUserComponent,
      canActivate: [AuthGuard]
    },
    {
      path: 'list-grupos',
      component: ListGrupoComponent,
      canActivate: [AuthGuard]
    },
    {
      path: 'cad-grupos',
      component: CadGrupoComponent,
    },
    {
      path: 'change-password',
      component: ChangePasswordComponent,
      canActivate: [AuthGuard]
    },
    {
      path: 'associar',
      component: AssociarGrupoComponent,
      canActivate: [AuthGuard]
    },
    {
      path: '',
      redirectTo: 'login',
      pathMatch: 'full',
    },
    {
      path: 'login',
      component: LoginFormComponent
    },

    {
      path: '**',
      component: NotFoundComponent,
    },
    {
      path: 'not-found',
      component: NotFoundComponent,

    }
  ],
}];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class PagesRoutingModule {
}

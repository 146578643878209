import { Component, OnInit, TemplateRef } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { NbDialogService } from '@nebular/theme';
import { HttpClient } from '@angular/common/http';
import { Router, ActivatedRoute } from '@angular/router';
import { Painel } from '../../shared/classModels/painel';
import { UsuarioService } from '../../shared/services/usuario.service';
import { UserDTO } from './userDTO';
import { GrupoService } from '../../shared/services/grupo.service';
import * as $ from 'jquery';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'ngx-list-user',
  templateUrl: './list-user.component.html',
  styleUrls: ['./list-user.component.scss']
})
export class ListUserComponent implements OnInit {

  public users: any[] = [];
  grupos : any[] = [];
  public idUserDelete: number;
  gruposSelecionados: any[] = [];
  indexGrupo: String;
  public nomeUser = '';
  usuarioEditado: any;
  grupoAdequado: any;
  usuario: any;

  idUsuario: number;
  teste: void;
  gruposFull: any[] = [];
  listaGrupoAnterior: any[] = [];

  pageHeader: string = '';
  inputNome: string = '';
  btnAdicionar: string = '';
  tableNome: string = '';
  tableLogin: string = '';
  tableAcao: string = '';
  tooltipExcluir: string = '';
  tooltipEditar: string = '';
  titleDialog: string = '';
  textDialog: string = '';
  btnContinuar: string = '';
  btnFechar: string = '';

  constructor(
    private dialogService: NbDialogService,
    private httpClient: HttpClient,
    private usuarioService: UsuarioService,
    private grupoService: GrupoService,
    private router: Router,
    private translate: TranslateService
    ) {
    }

  ngOnInit() {

    // INÍCIO - Tradução de conteúdo
    this.translate.stream('list-user.title').subscribe(res => {
      this.pageHeader = res;
    });
    this.translate.stream('input.nome').subscribe(res => {
      this.inputNome = res;
    });
    this.translate.stream('botao.addUsuario').subscribe(res => {
      this.btnAdicionar = res;
    });
    this.translate.stream('botao.fechar').subscribe(res => {
      this.btnFechar = res;
    });
    this.translate.stream('botao.continuar').subscribe(res => {
      this.btnContinuar = res;
    });
    this.translate.stream('table.nome').subscribe(res => {
      this.tableNome = res;
    });
    this.translate.stream('table.login').subscribe(res => {
      this.tableLogin = res;
    });
    this.translate.stream('table.acao').subscribe(res => {
      this.tableAcao = res;
    });
    this.translate.stream('tooltip.excluir').subscribe(res => {
      this.tooltipExcluir = res;
    });
    this.translate.stream('tooltip.editar').subscribe(res => {
      this.tooltipEditar = res;
    });
    this.translate.stream('dialog.titleRemoverUsuario').subscribe(res => {
      this.titleDialog = res;
    });
    this.translate.stream('dialog.textRemoverUsuario').subscribe(res => {
      this.textDialog = res;
    });
    // FIM - Tradução de conteúdo

    this.usuarioService.getAll().subscribe(result => {
      this.users = result;
    });  
  }

  onChangeStatus(newValue, idUsuario: number){
    this.usuarioService.updateUsuarioStatus(idUsuario).subscribe()
  }

  onChangeAdminStatus(newValue, idUsuario: number){
    this.usuarioService.switchAdminStatus(idUsuario).subscribe()
  }

  openModalConfirmation(dialog: TemplateRef<any>, idUsuario: number) {
    this.idUserDelete = idUsuario;
    this.dialogService.open(dialog, { context: 'dialog' });    
  }

  deletePainel(){
    this.usuarioService.deleteUsuario(this.idUserDelete).subscribe(result => {
      this.ngOnInit();   
    });
  }

  salvarEdit() {
    this.usuarioEditado.dsNome = $('.nome-editado').val()
  }

  openAdd() {
    this.router.navigate(["/add-users"]); 
  }

  openEdit(user: any){
    this.router.navigate(["/add-users", user]); 
  }

}

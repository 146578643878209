import 'rxjs/add/operator/toPromise';

import { HttpClient, HttpHeaders } from '@angular/common/http';

import { Injectable } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';
import { environment } from './../../environments/environment';
import { MenuPermissoesService } from '../menuPermissoes.service';


@Injectable({
  providedIn: 'root'
})
export class AuthService {

  oauthUrl: string;
  tokensRevokeUrl: string;
  jwtPayload: any;


  constructor(
    private http: HttpClient,
    private jwt: JwtHelperService


  ) {
    this.oauthUrl = `${environment.hosts.bipo_backend_rest}/oauth/token`;
    this.tokensRevokeUrl = `${environment.hosts.bipo_backend_rest}/tokens/revoke`;


    this.carregarToken();
  }



  logout() {
    return this.http.delete(this.tokensRevokeUrl, { withCredentials: true })
      .toPromise()
      .then(() => {
        this.limparAccessToken();
            });
  }

  login(usuario: string, senha: string, gRecaptchaResponse: string): Promise<void>{

    //limpando token antigo
    this.limparAccessToken();

    const headers = new HttpHeaders()
    .append('Content-Type', 'application/x-www-form-urlencoded')
    .append('Authorization', 'Basic YW5ndWxhcjpAbmd1bEByMA==');
    const body = `username=${usuario}&password=${senha}&grant_type=password&gRecaptchaResponse=${gRecaptchaResponse}`;
    console.log("this.oauthUrl", this.oauthUrl)
    return this.http.post(this.oauthUrl, body, { headers, withCredentials: true })
    .toPromise()
    .then(response => {
      this.armazenarToken(response['access_token']);
    })
    .catch(response => {
      const responseError = response.error;
      if (response.status === 400) {
        if (responseError.error === 'invalid_grant') {
          return Promise.reject('Usuário/senha inválida ou usuário desativado.');
        } else if (responseError.error === 'Bad Request') {
          return Promise.reject(responseError.message);
        }
      }
      return Promise.reject(response);
  });
  }

  obterNovoAccessToken(): Promise<void> {

    //limpando token antigo
    this.limparAccessToken();
    
    const headers = new HttpHeaders()
    .append('Content-Type', 'application/x-www-form-urlencoded')
    .append('Authorization', 'Basic YW5ndWxhcjpAbmd1bEByMA==');

    const body = 'grant_type=refresh_token';

    return this.http.post(this.oauthUrl, body,
        { headers, withCredentials: true })
      .toPromise()
      .then(response => {
        this.armazenarToken(response['access_token']);


        return Promise.resolve(null);
      })
      .catch(response => {
        return Promise.resolve(null);
      });
  }

  private armazenarToken(token: string){
    this.jwtPayload = this.jwt.decodeToken(token);
    localStorage.setItem('token', token);
  }

  isAccessTokenInvalido() {
    const token = localStorage.getItem('token');
    
    return !token || this.jwt.isTokenExpired(token);

  }

  limparAccessToken() {
    localStorage.removeItem('token');
    this.jwtPayload = null;
  }

  temPermissao(permissao: string){
    return this.jwtPayload && this.jwtPayload.authorities.includes(permissao);
  }

  temQualquerPermissao(roles) {
    for (const role of roles) {
      if (this.temPermissao(role)) {
        return true;
      }
    }

    return false;
  }


  private carregarToken(){
    const token = localStorage.getItem('token');

    if(token){
      this.armazenarToken(token);
    }
  }
}

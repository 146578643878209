import { Component, OnInit } from '@angular/core';

import { AuthService } from './../auth.service';
import { ErrorHandlerService } from '../error-handler.service';
import { FormControl } from '@angular/forms';
import { MenuPermissoesService } from './../../menuPermissoes.service';
import { Router } from '@angular/router';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'ngx-login-form',
  templateUrl: './login-form.component.html',
  styleUrls: ['./login-form.component.scss'],
})
export class LoginFormComponent implements OnInit {
  tira = false;

  siteKey: string;
  user = '';
  pass = '';
  invalido: String;
  gRecaptchaResponse = '';
  checkCaptcha: boolean;

  constructor(
    private router: Router,
    private auth: AuthService,
    private errorHandler: ErrorHandlerService,
    private menuAdminService: MenuPermissoesService
  ) {
    this.siteKey = environment.recaptcha;
    this.checkCaptcha = false
  }

  ngOnInit() {}
  

  login(usuario: string, senha: string) {
    this.auth
      .login(usuario, senha, this.gRecaptchaResponse)
      .then(() => {
        this.router.navigate(['paineis']);
        setTimeout(() => {
          window.location.reload();
        }, 100);
        this.menuAdminService.reset();
      })
      .catch(erro => {
        this.pass = '';
        this.errorHandler.handle(erro);
      });
  }

  resolved(captchaResponse: string) {
    if(captchaResponse !== null) {
      this.gRecaptchaResponse = captchaResponse;
      this.checkCaptcha = true;
      //console.log(`Resolved captcha with response: ${captchaResponse}`);
    } else {
      this.checkCaptcha = false;
    }
  }

  handleReset() {

  }
  handleExpire() {

  }
  handleLoad(){
    
  }
  handleSuccess($event) {
    console.log(`Resolved captcha with response: ${$event}`);

  }
}

import { Router } from '@angular/router';
import { Component } from '@angular/core';

@Component({
  selector: 'ngx-one-column-layout',
  styleUrls: ['./one-column.layout.scss'],
  template: `
    <nb-layout windowMode>
      <nb-layout-header *ngIf="exibindo()" fixed>
        <ngx-header></ngx-header>
      </nb-layout-header>

      <nb-sidebar class="menu-sidebar" *ngIf="exibindo()" tag="menu-sidebar" responsive>
        <ng-content select="nb-menu"></ng-content>
      </nb-sidebar>

      <nb-layout-column class="{{exibindo() ? '' : 'bg-minsait'}}">
        <ng-content select="router-outlet"></ng-content>
      </nb-layout-column>

      <nb-layout-footer *ngIf="exibindo()" fixed>
        <ngx-footer></ngx-footer>
      </nb-layout-footer>
    </nb-layout>
  `,
})
export class OneColumnLayoutComponent {
  constructor(
    private router: Router) {
  }

  exibindo(){
    return this.router.url !== '/login';
  }

}

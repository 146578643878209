import { HttpClient, HttpHeaders, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { Observable, of } from 'rxjs';

import { catchError, map, tap } from 'rxjs/operators';

import { AppConstants} from '../constants/shared.constants';
import { Injectable } from '@angular/core';
import { NbMenuItem } from '@nebular/theme';
import { Painel } from '../classModels/painel';
import { GrupoDTO } from '../../pages/list-grupo/grupoDTO';


const basePathEndpoint = '/api/config/auth-pbi';

@Injectable({
  providedIn: 'root'
})

export class AuthPbiService {

  _baseHttpHeader : HttpHeaders;
  _baseURL : string;
  _pathEndpoint : string;

  constructor(private http: HttpClient) { 
    this._baseURL = AppConstants.baseURL;
    this._baseHttpHeader = AppConstants.baseHttpOptions;
    this._pathEndpoint = this._baseURL + basePathEndpoint;
  }

  saveAuthPbi(grupoAuthPbi): Observable<any>{
    var request = this.http.post<any>(this._pathEndpoint, grupoAuthPbi);
    return request; 
  }

  getAuthPbi():Observable<any> {
    return this.http.get<any>(this._pathEndpoint);    
  }

}
import { BpoHttpInterceptor } from './bpo-http-interceptor';
import { JwtHelperService } from '@auth0/angular-jwt';
import { AuthService } from './auth.service';
import { NgModule } from '@angular/core';
import { NbMenuModule, NbTabsetModule, NbTooltipModule, NbBadgeModule } from '@nebular/theme';

import { ThemeModule } from '../@theme/theme.module';
import { PagesComponent } from './pages.component';
import { PagesRoutingModule } from './pages-routing.module';
import { NgxEchartsModule } from 'ngx-echarts';
import { UiSwitchModule } from 'ngx-ui-switch';

import {
  NbButtonModule,
  NbCardModule,
  NbCheckboxModule,
  NbIconModule,
  NbInputModule,
  NbRadioModule,
  NbSelectModule,
} from '@nebular/theme';
import { NotFoundComponent } from './not-found/not-found.component';

import { ReactiveFormsModule } from '@angular/forms';
import { Ng2SmartTableModule } from 'ng2-smart-table';
import { SharedModule } from '../shared/shared.module';
import { PaineisComponent } from './paineis/paineis.component';
import { FilterPipe, SafePipe } from '../pipes/custom.pipe';
import { LoginFormComponent } from './login-form/login-form.component';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { AuthGuard } from './auth.guard';
import { AddPainelComponent } from './add-painel/add-painel.component';
import { ListPainelComponent } from './list-painel/list-painel.component';
import { AddUserComponent } from './add-user/add-user.component';
import { ListUserComponent } from './list-user/list-user.component';
import { ListGrupoComponent } from './list-grupo/list-grupo.component';
import { AssociarGrupoComponent } from './associar-grupo/associar-grupo-painel.component';
import { MenuPermissoesService } from '../menuPermissoes.service';
import { ChangePasswordComponent } from './change-password/change-password.component';
import { CadGrupoComponent } from './cad-grupo/cad-grupo.component';
import { AutenticacaoPowerbiComponent } from './autenticacao-powerbi/autenticacao-powerbi.component';



@NgModule({
  imports: [
    PagesRoutingModule,
    ThemeModule,
    NbMenuModule,
    Ng2SmartTableModule,
    NbSelectModule,
    NbCheckboxModule,
    NbCardModule,
    NbInputModule,
    NbButtonModule,
    NbRadioModule,
    NbIconModule,
    NgxEchartsModule,
    ReactiveFormsModule,
    NbTabsetModule,
    SharedModule,
    NbTooltipModule,
    NbBadgeModule,
    UiSwitchModule,
  ],
  declarations: [
    PagesComponent,
    NotFoundComponent,
    PaineisComponent,
    LoginFormComponent,
    SafePipe,
    FilterPipe,
    AddPainelComponent,
    ListPainelComponent,
    AddUserComponent,
    ListUserComponent,
    ListGrupoComponent,
    AssociarGrupoComponent,
    ChangePasswordComponent,
    CadGrupoComponent,
    AutenticacaoPowerbiComponent,
    
    
  ],
   providers: [
    AuthService,
    JwtHelperService,
    MenuPermissoesService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: BpoHttpInterceptor,
      multi: true
  },
  AuthGuard

  ]
})
export class PagesModule {
}

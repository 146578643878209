import { Component, OnInit, TemplateRef } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { NbDialogService } from '@nebular/theme';
import { DayWeek } from '../../enumerations/day-week';
import { TypeTrigger } from '../../enumerations/type-trigger';
import { Month } from '../../enumerations/month';
import { HttpClient } from '@angular/common/http';
import { PainelService } from '../../shared/services/painel.service';
import { Router, ActivatedRoute } from '@angular/router';
import { Painel } from '../../shared/classModels/painel';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'ngx-list-painel',
  templateUrl: './list-painel.component.html',
  styleUrls: ['./list-painel.component.scss']
})
export class ListPainelComponent implements OnInit {

  public paineis: any[] = [];
  public idPainelDelete: number;
  public nomePainel = '';

  pageHeader: string = '';
  inputNome: string = '';
  btnAdicionar: string = '';
  tableNome: string = '';
  tableDescricao: string = '';
  tableAcao: string = '';
  tooltipExcluir: string = '';
  tooltipEditar: string = '';
  titleDialog: string = '';
  textDialog: string = '';
  btnContinuar: string = '';
  btnFechar: string = '';

  constructor(
    private dialogService: NbDialogService,
    private httpClient: HttpClient,
    private painelService: PainelService,
    private router: Router,
    private translate: TranslateService
    ) {}

  ngOnInit() {
    // INÍCIO - Tradução de conteúdo
    this.translate.stream('list-painel.title').subscribe(res => {
      this.pageHeader = res;
    });
    this.translate.stream('input.nome').subscribe(res => {
      this.inputNome = res;
    });
    this.translate.stream('botao.addPainel').subscribe(res => {
      this.btnAdicionar = res;
    });
    this.translate.stream('botao.fechar').subscribe(res => {
      this.btnFechar = res;
    });
    this.translate.stream('botao.continuar').subscribe(res => {
      this.btnContinuar = res;
    });
    this.translate.stream('table.nomePainel').subscribe(res => {
      this.tableNome = res;
    });
    this.translate.stream('table.descricao').subscribe(res => {
      this.tableDescricao = res;
    });
    this.translate.stream('table.acao').subscribe(res => {
      this.tableAcao = res;
    });
    this.translate.stream('tooltip.excluir').subscribe(res => {
      this.tooltipExcluir = res;
    });
    this.translate.stream('tooltip.editar').subscribe(res => {
      this.tooltipEditar = res;
    });
    this.translate.stream('dialog.titleRemoverPainel').subscribe(res => {
      this.titleDialog = res;
    });
    this.translate.stream('dialog.textRemoverPainel').subscribe(res => {
      this.textDialog = res;
    });
    // FIM - Tradução de conteúdo

    this.painelService.getAll().subscribe(result => {
      this.paineis = result;
    });
  }

  onChangeStatus(newValue, idPainel: number){
    this.painelService.updatePainelStatus(idPainel).subscribe()
  }

  openModalConfirmation(dialog: TemplateRef<any>, idPainel: number) {
    this.idPainelDelete = idPainel;
    this.dialogService.open(dialog, { context: 'dialog' });    
  }

  deletePainel(){
    this.painelService.deletePainel(this.idPainelDelete).subscribe(result => {
      this.ngOnInit();   
    });
  }

  openEdit(painel: Painel){
    this.router.navigate(["/add-paineis", painel]); 
  }

  openAdd() {
    this.router.navigate(["/add-paineis"]);
  }



}

// const PROTOCOL = 'https://';
// const BASE_HOST = 'bibpo.indrabrasil.com.br';

const CONTEXT_PATH = '/analyticsbpo';
// const CONTEXT_PATH = 'http://10.83.252.27:8014/analyticsbpo';
// const CONTEXT_PATH = 'http://localhost:9090/analyticsbpo';

export const environment = {
  production: true,
  hosts: {
    bipo_whitelisted_domains: `${CONTEXT_PATH}/api`,
    bipo_blacklisted_routes:  `${CONTEXT_PATH}/api/oauth/token`,
    bipo_backend_rest: CONTEXT_PATH,
  },
  // recaptcha: "6LceJNwZAAAAAHBvFfsEFh576G6XpigabdlGOWGp",
  recaptcha: "6LektfMZAAAAAGA93tzkLfA4FSCzEUPjMLUCcIel",
};
import { Injectable } from '@angular/core';
import { SmartTableData } from '../data/smart-table';

@Injectable()
export class SmartTableService extends SmartTableData {

  data = [{
    id: 1,
    name: 'Processo 1'
  }, {
    id: 2,
    name: 'Processo 2',
  }];

  getData() {
    return this.data;
  }
}

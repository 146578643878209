import { ToasterConfig } from 'angular2-toaster';
import { AuthService } from './../auth.service';
import { ActivatedRoute, Router } from '@angular/router';
import { GrupoService } from './../../shared/services/grupo.service';
import { AuthPbiService } from './../../shared/services/auth-pbi.service';
import { PainelService } from './../../shared/services/painel.service';
import { NbDialogService, NbToastrService, NbComponentStatus, NbGlobalPosition, NbGlobalPhysicalPosition } from '@nebular/theme';
import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'ngx-autenticacao-powerbi',
  templateUrl: './autenticacao-powerbi.component.html',
  styleUrls: ['./autenticacao-powerbi.component.scss']
})
export class AutenticacaoPowerbiComponent implements OnInit {

  // Título
  pageHeader: string = '';

  // Item recebido da resposta
  responseAutorizador: any;

  // Controlado do Tipo de Autenticacao radio 
  exibeService: boolean = true;
  exibeMaster: boolean = false;

  // Toaster
  config: ToasterConfig;
  destroyByClick = true;
  duration = 5000;
  titleSuccess = 'OK!';
  titleFail = 'ERRO!';
  contentSuccess = `Autenticação incluída com Sucesso`;
  contentFail = `Autenticação não foi incluído, tente novamente mais tarde`;
  hasIcon = true;
  position: NbGlobalPosition = NbGlobalPhysicalPosition.TOP_RIGHT;
  preventDuplicates = false;
  statusSuccess: NbComponentStatus = 'success';
  statusFail: NbComponentStatus = 'danger';

  // Models itens
  cliente: string = '';
  tenant: string = '';
  user: string = '';
  secret: string = '';
  idResult: number = 1;
  options = [
    { value: 'M', label: 'Master User', checked: false },
    { value: 'S', label: 'Service Principal', checked: false }
  ];
  option;

  // Validações
  showErrorCliente: boolean = false;
  showErrorUser: boolean = false;
  showErrorSecret: boolean = false;
  showErrorTenant: boolean = false;
  showErrorName: boolean = false;

  inputTipoAutenticacao: string = "";
  btnSalvar: string = "";

  // Objeto para salvar
  grupoAuthPbi = 
    {
      authSecret: '',
      authType: '',
      clientId: '',
      id: 0,
      tenantId: '',
      username: '',
    };

  constructor(
    private dialogService: NbDialogService,
    private painelService: PainelService,
    private toastrService: NbToastrService,
    private grupoService: GrupoService,
    private authPbiService: AuthPbiService,
    private route: ActivatedRoute,
    private auth: AuthService,
    private router: Router,
    private translate: TranslateService) { }

  ngOnInit() {
    // INÍCIO - Tradução de conteúdo
    this.translate.stream('input.tipoautenticacao').subscribe(res => {
      this.inputTipoAutenticacao = res;
    });
    this.translate.stream('botao.salvar').subscribe(res => {
      this.btnSalvar = res;
    });
    // FIM - Tradução de conteúdo
    this.authPbiService.getAuthPbi().subscribe(result => {
      this.responseAutorizador = result;
      if (this.responseAutorizador === null) {
        // this.pageHeader = 'Cadastrar Autenticação PowerBI';
        this.translate.stream('autenticacao-powerbi.title2').subscribe(res => {
          this.pageHeader = res;
        });
        this.option = 'M';
        this.options[0].checked = true;
        this.options[1].checked = false;
        this.exibeService = true;
        this.exibeMaster = false;
        // Models
        this.cliente = '';
        this.tenant = '';
        this.user = '';
        this.secret = '';
        this.secret = '';
        this.idResult = 1;
      } else if (this.responseAutorizador.authType === 'M') {
        // this.pageHeader = 'Editar Autenticação PowerBI';
        this.translate.stream('autenticacao-powerbi.title1').subscribe(res => {
          this.pageHeader = res;
        });
        this.option = 'M';
        this.options[0].checked = true;
        this.options[1].checked = false;
        this.exibeService = true;
        this.exibeMaster = false;
        // Models
        this.cliente = this.responseAutorizador.clientId;
        this.tenant = this.responseAutorizador.tenantId;
        this.user = this.responseAutorizador.username;
        this.secret = this.responseAutorizador.authSecret;
        this.idResult = this.responseAutorizador.id;
      } else {
        // this.pageHeader = 'Editar Autenticação PowerBI';
        this.translate.stream('autenticacao-powerbi.title1').subscribe(res => {
          this.pageHeader = res;
        });
        this.option = 'S';
        this.options[0].checked = false;
        this.options[1].checked = true;
        this.exibeService = false;
        this.exibeMaster = true;
        // Models
        this.cliente = this.responseAutorizador.clientId;
        this.tenant = this.responseAutorizador.tenantId;
        this.user = this.responseAutorizador.username;
        this.secret = this.responseAutorizador.authSecret;
        this.idResult = this.responseAutorizador.id;
      }
    },
    err => {
      this.showToast(this.statusFail, this.titleFail, this.contentFail);
    });
  }

  tipoAuth(event) {
    if (event === 'M') {
      this.exibeService = true
      this.exibeMaster = false
    } else {
      this.exibeService = false
      this.exibeMaster = true
    }
  }

  showSecret() {
    var x = <HTMLInputElement>document.getElementById("inputSecret");
    if (x.type === "password") {
      x.type = "text";
    } else {
      x.type = "password";
    }
  }

  saveAutenticacao() {
    if (this.option === 'M') {
      if ((this.cliente !== '') && (this.user !== '') && (this.secret !== '')) {
        this.showErrorCliente = false;
        this.showErrorUser = false;
        this.showErrorSecret = false;
        this.showErrorName = false;

        this.grupoAuthPbi.id = this.idResult
        this.grupoAuthPbi.authSecret = this.secret
        this.grupoAuthPbi.clientId = this.cliente
        this.grupoAuthPbi.authType = this.option
        this.grupoAuthPbi.tenantId = ''
        this.grupoAuthPbi.username = this.user

        this.authPbiService.saveAuthPbi(this.grupoAuthPbi).subscribe(result => { 
          this.showToast(this.statusSuccess, this.titleSuccess, this.contentSuccess);
        })
      } else {
        this.validationCliente();
        this.validationUser();
        this.validationSecret();
        this.validationName();
      }
    } else if (this.option === 'S') {
      if ((this.cliente !== '') && (this.tenant !== '') && (this.secret !== '')) {
        this.showErrorCliente = false;
        this.showErrorTenant = false;
        this.showErrorSecret = false;
        this.showErrorName = false;

        this.grupoAuthPbi.id = this.idResult;
        this.grupoAuthPbi.authSecret = this.secret;
        this.grupoAuthPbi.clientId = this.cliente;
        this.grupoAuthPbi.authType = this.option;
        this.grupoAuthPbi.tenantId = this.tenant;
        this.grupoAuthPbi.username = '';

        this.authPbiService.saveAuthPbi(this.grupoAuthPbi).subscribe(result => { 
          this.showToast(this.statusSuccess, this.titleSuccess, this.contentSuccess);
        })
      } else {
        this.validationCliente();
        this.validationTenant();
        this.validationSecret();
        this.validationName();
      }
    } else {
      console.log('ERRO NA APLICAÇÃO')
    }
  }

  validationCliente(){
    if (this.cliente === '') {
      this.showErrorCliente = true;
    } else {
      this.showErrorCliente = false;
    }
  }

  validationUser(){
    if (this.user === '') {
      this.showErrorUser = true;
    } else {
      this.showErrorUser = false;
    }
  }

  validationSecret(){
    if (this.secret === '') {
      this.showErrorSecret = true;
    } else {
      this.showErrorSecret = false;
    }
  }

  validationTenant(){
    if (this.tenant === '') {
      this.showErrorTenant = true;
    } else {
      this.showErrorTenant = false;
    }
  }

  validationName(){
    if (this.option === '') {
      this.showErrorName = true;
    } else {
      this.showErrorName = false;
    }
  }

  private showToast(type: NbComponentStatus, title: string, body: string) {
    const config = {
      status: type,
      destroyByClick: this.destroyByClick,
      duration: this.duration,
      hasIcon: this.hasIcon,
      position: this.position,
      preventDuplicates: this.preventDuplicates,
    };
    this.toastrService.show(
      body,
      title,
      config);
  }

}

import { Component, OnInit, TemplateRef } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { NbDialogService } from '@nebular/theme';
import { DayWeek } from '../../enumerations/day-week';
import { TypeTrigger } from '../../enumerations/type-trigger';
import { Month } from '../../enumerations/month';
import { Painel } from '../../shared/classModels/painel';
import { ToasterConfig } from 'angular2-toaster';
import {
  NbComponentStatus,
  NbGlobalLogicalPosition,
  NbGlobalPhysicalPosition,
  NbGlobalPosition,
  NbToastrService,
} from '@nebular/theme';
import { scheduled } from 'rxjs';
import { PainelService } from '../../shared/services/painel.service';
import { Router, ActivatedRoute } from '@angular/router';
import { Grupo } from '../../shared/classModels/grupo';

@Component({
  selector: 'ngx-associar-grupo-painel',
  templateUrl: './associar-grupo-painel.component.html',
  styleUrls: ['./associar-grupo-painel.component.scss']
})
export class AssociarGrupoComponent implements OnInit {

  nomeGrupo:string = '';
  listaAssociados: [] = [];
  listaTodos: [] = [];
  grupo = null;
  fruits: string[] = [
    'Lemons',
    'Raspberries',
    'Strawberries',
    'Blackberries',
    'Kiwis',
    'Grapefruit',
    'Avocado',
    'Watermelon',
    'Cantaloupe',
    'Oranges',
    'Peaches',
  ];
  

  config: ToasterConfig;

  index = 1;
  destroyByClick = true;
  duration = 5000;
  titleSuccess = 'OK!';
  titleFail = 'ERRO!';
  contentSuccess = `Painel incluído com Sucesso`;
  contentFail = `Painel não foi incluído, tente novamente mais tarde`;
  hasIcon = true;
  position: NbGlobalPosition = NbGlobalPhysicalPosition.TOP_RIGHT;
  preventDuplicates = false;
  statusSuccess: NbComponentStatus = 'success';
  statusFail: NbComponentStatus = 'danger';
  showErrorName:boolean = false;
  showErrorUrl:boolean = false;

  constructor(
    private dialogService: NbDialogService,
    private painelService: PainelService,
    private toastrService: NbToastrService,
    private route: ActivatedRoute) { 
    }

  ngOnInit() {
    this.route.params.subscribe(params => {
      this.grupo = params;
    });
  }
  savePainel(){
    
  }

  open(dialog: TemplateRef<any>) {
    this.dialogService.open(dialog, { context: 'dialog' });
  }

  cleanErrorsValidators(){
    this.showErrorName = false;
    this.showErrorUrl = false;
  }

  validationName(){
    
  }

  validationUrl(){
    
  }


  cleanComponents(){
  }

  private showToast(type: NbComponentStatus, title: string, body: string) {
    const config = {
      status: type,
      destroyByClick: this.destroyByClick,
      duration: this.duration,
      hasIcon: this.hasIcon,
      position: this.position,
      preventDuplicates: this.preventDuplicates,
    };
    this.toastrService.show(
      body,
      title,
      config);  
  }
}


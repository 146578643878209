import { Component, OnInit, TemplateRef } from '@angular/core';
import {
  NbComponentStatus,
  NbGlobalLogicalPosition,
  NbGlobalPhysicalPosition,
  NbGlobalPosition,
  NbToastrService,
  NbMenuService,
} from '@nebular/theme';


import { scheduled } from 'rxjs';
import { Observable } from 'rxjs';
import { PainelService } from '../../shared/services/painel.service';
import { AuthService } from '../auth.service';
import * as pbi from 'powerbi-client';
import { ErrorHandlerService } from '../error-handler.service';

@Component({
  selector: 'ngx-paineis',
  templateUrl: './paineis.component.html',
  styleUrls: ['./paineis.component.scss']
})

export class PaineisComponent implements OnInit {
 
  urlPainel: string = '';
  tokenPainel: string = '';
  painelNome: string = '';
  painelId: string = '';
  usuarioID: number = 0;
  tokenPBI: string;
  powerbi: any;


  constructor(
    private painelService: PainelService,
    private menuService: NbMenuService,
    private auth: AuthService,
    private errorHandler: ErrorHandlerService) { 
    }

  ngOnInit() {
    this.usuarioID = this.auth.jwtPayload.id;
    this.menuService.onItemClick().subscribe(( event ) => {      
      this.painelNome = event.item.title;
      this.painelId = event.item.data;
      this.urlPainel = '';
      this.tokenPainel = '';     
      
      if(this.usuarioID && this.painelId) {
        this.painelService.getPainelLink(this.usuarioID, this.painelId).subscribe(result => { 

          //ajustar carregamento/exibição
          this.urlPainel = result.embedUrl;
          this.tokenPainel = result.embedToken;        
  
            const config = {
              type: 'report',
              tokenType: pbi.models.TokenType.Embed,
              uniqueId: '4f615bef-8eea-4d31-bac1-33d20222b29f',
              embedUrl: this.urlPainel,
              accessToken: this.tokenPainel
            };
        
            // Grab the reference to the div HTML element that will host the report.
            const reportsContainer = <HTMLElement>document.getElementById(
              'reportsContainer'
            );
        
            // Embed the report and display it within the div container.
            this.powerbi = new pbi.service.Service(
              pbi.factories.hpmFactory,
              pbi.factories.wpmpFactory,
              pbi.factories.routerFactory
            );
  
            // Reset div para receber novo conteúdo
            this.powerbi.reset(reportsContainer)
            
            const report = this.powerbi.embed(
              reportsContainer,
              config
            );
        
            // Report.off removes a given event handler if it exists.
            report.off('loaded');
            // Report.on will add an event handler which prints to Log window.
            // report.on('loaded', function() {
            //   console.log('Loaded');
            // });
        
            // report.off('pageChanged');
            // report.on('pageChanged', e => {
            //   console.log(e);
            // });
        
            // report.on('error', function(event) {
            //   console.log(event.detail);
            //   report.off('error');
            // });
        },
          error => {
            this.errorHandler.handle("Falha na autenticação para carregamento do painel. Contate o administrador do sistema.");
          }
        );
      }
    })
  }

  fullscreen() {
    const reportsContainer = <HTMLElement>document.getElementById('reportsContainer');
    let report = this.powerbi.get(reportsContainer);
    report.fullscreen();

    // Fullscreen não funcionando no Chrome apenas no build do servidor, necessário investigar (Funciona no Firefox)
    // o teste abaixo foi para tentar melhorar o entendimento do erro, mas sem sucesso.

    // const iframe = <HTMLElement>document.getElementsByTagName('iframe')[0];
    // var requestFullScreen = iframe.requestFullscreen;
    // requestFullScreen.call(iframe)
    //   .then(response => { 
    //     console.log("Fullscreen Response: ");  
    //     console.log(response);
    //     })
    //   .catch(err => {
    //     console.log("Fullscreen Error: ");  
    //     console.log(err);
    //   })
}

}
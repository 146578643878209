import { HttpClient, HttpHeaders, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { Observable, of } from 'rxjs';

import { catchError, map, tap } from 'rxjs/operators';

import { AppConstants} from '../constants/shared.constants';
import { Injectable } from '@angular/core';
import { NbMenuItem } from '@nebular/theme';
import { Painel } from '../classModels/painel';
import { Usuario } from '../classModels/usuario';
import { ChangePassword } from '../classModels/changePassword';
import { Grupo } from '../classModels/grupo';


const basePathEndpoint = '/api/usuario';

@Injectable({
  providedIn: 'root'
})

export class UsuarioService {

  _baseHttpHeader : HttpHeaders;
  _baseURL : string;
  _pathEndpoint : string;

  constructor(private http: HttpClient) { 
    this._baseURL = AppConstants.baseURL;
    this._baseHttpHeader = AppConstants.baseHttpOptions;
    this._pathEndpoint = this._baseURL + basePathEndpoint;
  }

  addUsuario(usuario: Usuario): Observable<any>{
    let endpoint = `${this._pathEndpoint}/save`;
    var json = JSON.stringify(usuario);
    return this.http.post(endpoint,json, {headers: this._baseHttpHeader});    
  }

  getAll():Observable<any> {
    let endpoint = `${this._pathEndpoint}/all`;
    return this.http.get<any>(endpoint, {headers: this._baseHttpHeader});    
  }

  getUserById(idUser: number):Observable<any> {
    let endpoint = `${this._pathEndpoint}/${idUser}`;
    return this.http.get<any>(endpoint, {headers: this._baseHttpHeader});    
  }

  updateUsuarioStatus(painelId: number): Observable<any>{
    let endpoint = `${this._pathEndpoint}/change-status/${painelId}`;
    return this.http.put(endpoint, {headers: this._baseHttpHeader});    
  }

  deleteUsuario(painelId: number): Observable<any>{
    let endpoint = `${this._pathEndpoint}/delete-painel/${painelId}`;
    return this.http.delete(endpoint, {headers: this._baseHttpHeader});    
  }

  actualPasswordValidation(changePassword: ChangePassword){
    let endpoint = `${this._pathEndpoint}/confirm-password`;
    var json = JSON.stringify(changePassword);
    return this.http.post(endpoint,json, {headers: this._baseHttpHeader});    
  }

  changePassword(changePassword: ChangePassword){
    let endpoint = `${this._pathEndpoint}/change-password`;
    var json = JSON.stringify(changePassword);
    return this.http.post(endpoint,json, {headers: this._baseHttpHeader, observe: 'response'});    
  }

  switchAdminStatus(painelId: number): Observable<any>{
    let endpoint = `${this._pathEndpoint}/switch-admin-status/${painelId}`;
    return this.http.put(endpoint, {headers: this._baseHttpHeader});    
  }

  getUsuariosByGrupo(grupoId: Number): Observable<any>{
    let endpoint = `${this._pathEndpoint}/by-grupo/${grupoId}`;
    return this.http.get(endpoint, {headers: this._baseHttpHeader}); 
  }

  getUsuariosByLogin(usuarioLogin: String): Observable<any>{
    let endpoint = `${this._pathEndpoint}/by-login/${usuarioLogin}`;
    return this.http.get(endpoint, {headers: this._baseHttpHeader}); 
  }


}
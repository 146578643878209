import { Grupo } from './grupo';

export class Usuario{
	id: number;
    nome: string;
	cpf: string;
	login: string;
	senha: string;
	grupo: Grupo;

	constructor(id?:number, nome?:string, cpf?: string, login?:string, senha?: string, grupo?: Grupo){
		this.id = id;
		this.nome = nome;
		this.cpf = cpf;
		this.login = login;
		this.senha = senha;
		this.grupo = grupo;
	}
}


import { Component, OnInit, TemplateRef } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { NbDialogService } from '@nebular/theme';
import { ToasterConfig } from 'angular2-toaster';
import {
  NbComponentStatus,
  NbGlobalLogicalPosition,
  NbGlobalPhysicalPosition,
  NbGlobalPosition,
  NbToastrService,
} from '@nebular/theme';
import { scheduled } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '../auth.service';
import { UsuarioService } from '../../shared/services/usuario.service';
import { ChangePassword } from '../../shared/classModels/changePassword';
import { truncateSync } from 'fs';
import { error } from 'selenium-webdriver';

@Component({
  selector: 'ngx-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss']
})
export class ChangePasswordComponent implements OnInit {


  
  erroSenhaAtualVazia: boolean = false;
  erroSenhasDiferentes: boolean = false;
  erroNovaSenhaVazia: boolean = false;
  erroConfirmaNovaSenhaVazia: boolean = false;
  erroSenhaNovaPequena: boolean = false;
  actualPassword: string = "";
  newPassword: string = "";
  newPasswordConfirmation: string = "";
  idUsuario: number = 0;
  showErrorPassword: boolean = false;

  config: ToasterConfig;

  index = 1;
  destroyByClick = true;
  duration = 5000;
  titleSuccess = 'OK!';
  titleFail = 'ERRO!';
  contentSuccess = `Senha alterada com sucesso`;
  contentFail = `Senha não foi alterada, tente novamente mais tarde`;
  actualPasswordWrong = `Senha atual está incorreta`
  hasIcon = true;
  position: NbGlobalPosition = NbGlobalPhysicalPosition.TOP_RIGHT;
  preventDuplicates = true;
  statusSuccess: NbComponentStatus = 'success';
  statusFail: NbComponentStatus = 'danger';

  constructor(
    private dialogService: NbDialogService,
    private toastrService: NbToastrService,
    private route: ActivatedRoute,
    private router: Router,
    private usuarioService: UsuarioService,
    private auth: AuthService) { 
    };

  ngOnInit() {
    this.idUsuario = this.auth.jwtPayload.id;   
  }

  savePassword(){
    var changePassword: ChangePassword = new ChangePassword(this.idUsuario, this.newPassword, this.actualPassword)  
    this.usuarioService.changePassword(changePassword).subscribe(result => {
        this.showToast(this.statusSuccess, this.titleSuccess, this.contentSuccess);
    },
    err =>{
        if(err.status == 404){
            this.showToast(this.statusFail, this.titleFail, this.actualPasswordWrong);
        }else{
            this.showToast(this.statusFail, this.titleFail, this.contentFail);
        }
    });
  }
  
  newPasswordValidation(){
    if(this.newPassword.length >= 6){
        this.limpaValidaCampos();
        this.savePassword();
    }else{
        this.limpaValidaCampos();
        this.validaSenhaAtualVazia();
        this.validaSenhaNovaVazia();
        this.validaSenhaNovaConfirmacaoVazia();
        this.validaValidaTamanhoSenha();
        this.validaSenhasIguais();
    }
  }

  limpaValidaCampos(){
    this.erroSenhaAtualVazia = false;
    this.erroNovaSenhaVazia = false;
    this.erroConfirmaNovaSenhaVazia = false;
    this.erroSenhasDiferentes = false;
    this.erroSenhaNovaPequena = false;
  }

  validaSenhaAtualVazia(){
      if(!this.actualPassword){
        this.erroSenhaAtualVazia = true;
      }
  }

  validaSenhasIguais(){
    if(this.newPassword != this.newPasswordConfirmation){
        this.erroSenhasDiferentes = true;
    }
  }

  
    validaSenhaNovaVazia(){
        if(!this.newPassword){
        this.erroNovaSenhaVazia = true;
        }
    }

  
    validaSenhaNovaConfirmacaoVazia(){
        if(!this.newPasswordConfirmation){
        this.erroConfirmaNovaSenhaVazia = true;
        }
    }

    validaValidaTamanhoSenha(){
        if(this.newPassword.length < 6 || this.newPasswordConfirmation.length < 6){
        this.erroSenhaNovaPequena = true;
        }
    }
    

  private showToast(type: NbComponentStatus, title: string, body: string) {
    const config = {
      status: type,
      destroyByClick: this.destroyByClick,
      duration: this.duration,
      hasIcon: this.hasIcon,
      position: this.position,
      preventDuplicates: this.preventDuplicates,
    };
    this.toastrService.show(
      body,
      title,
      config);  
  }
}


import { AuthService } from './pages/auth.service';
import { Injectable } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';
import { NbMenuItem } from '@nebular/theme';
import { environment } from '../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class MenuPermissoesService {
  constructor(private auth: AuthService) {}

  _items: NbMenuItem[];

  get items(): NbMenuItem[] {
    if (!this._items) {
      this._items = this.composeMenuAdmin();
    }

    return this._items;
  }

  reset() {
    this._items = null;
  }

  composeMenuAdmin(): NbMenuItem[] {
    const menuVazio: NbMenuItem[] = [];

    const menuAdmin: NbMenuItem[] = [
      {
        title: 'Configurações',
        icon: 'settings-2-outline',
        children: [
          {
            title: 'Autenticação PowerBI',
            link: '/autenticacao-powerbi'
          }
        ]
      },
      {
        title: 'Gerenciamento de Painéis',
        icon: 'bar-chart-2-outline',
        children: [
          {
            title: 'Cadastrar Painel',
            link: '/add-paineis'
          },
          {
            title: 'Listar Painéis',
            link: '/list-paineis'
          }
        ]
      },
      {
        title: 'Gerenciamento de Usuários',
        icon: 'person-outline',
        children: [
          {
            title: 'Cadastrar Usuário',
            link: '/add-users'
          },
          {
            title: 'Listar Usuários',
            link: '/list-users'
          }
        ]
      },
      {
        title: 'Gerenciamento de Grupos',
        icon: 'swap-outline',
        children: [
          {
            title: 'Listar Grupos',
            link: '/list-grupos'
          },
          {
            title: 'Cadastrar Grupos',
            link: '/cad-grupos'
          }
        ]
      }
    ];
    if (this.auth.temPermissao('ROLE_CADASTRAR_USUARIO')) {
      return (this._items = menuAdmin);
    } else {
      return (this._items = menuVazio);
    }
  }
}
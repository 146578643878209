import { Component, OnInit, TemplateRef } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { NbDialogService } from '@nebular/theme';
import { DayWeek } from '../../enumerations/day-week';
import { TypeTrigger } from '../../enumerations/type-trigger';
import { Month } from '../../enumerations/month';
import { Painel } from '../../shared/classModels/painel';
import { ToasterConfig } from 'angular2-toaster';
import {
  NbComponentStatus,
  NbGlobalLogicalPosition,
  NbGlobalPhysicalPosition,
  NbGlobalPosition,
  NbToastrService,
} from '@nebular/theme';
import { scheduled } from 'rxjs';
import { PainelService } from '../../shared/services/painel.service';
import { Router, ActivatedRoute } from '@angular/router';
import { GrupoService } from '../../shared/services/grupo.service';
import { Grupo } from '../../shared/classModels/grupo';
import { AuthService } from '../auth.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'ngx-add-painel',
  templateUrl: './add-painel.component.html',
  styleUrls: ['./add-painel.component.scss']
})
export class AddPainelComponent implements OnInit {

  nomePainel:string = '';
  pageHeader: string = '';
  descricaoPainel:string = '';
  isActive:boolean = true;
  urlPbix: string;
  painel:Painel = new Painel();
  editPainel = null;
  listaGrupoAnterior: any[] = [];
  grupos: any[] = [];
  gruposSelecionados: any[] = [];
  idUsuarioAutorizador: number;

  config: ToasterConfig;

  index = 1;
  destroyByClick = true;
  duration = 5000;
  titleSuccess = 'OK!';
  titleFail = 'ERRO!';
  contentSuccess = `Painel incluído com Sucesso`;
  contentFail = `Painel não foi incluído, tente novamente mais tarde`;
  hasIcon = true;
  position: NbGlobalPosition = NbGlobalPhysicalPosition.TOP_RIGHT;
  preventDuplicates = false;
  statusSuccess: NbComponentStatus = 'success';
  statusFail: NbComponentStatus = 'danger';
  showErrorName:boolean = false;
  showErrorUrl:boolean = false;
  showErrorGrupo: boolean = false;

  btnSalvar: string = "";
  inputNome: string = "";
  inputDescricao: string = "";
  inputGrupo: string = "";
  placeholderGrupo: string = "";
  erroNome: string = "";
  erroPbix: string = "";
  erroGrupo: string = "";

  constructor(
    private dialogService: NbDialogService,
    private painelService: PainelService,
    private toastrService: NbToastrService,
    private grupoService: GrupoService,
    private route: ActivatedRoute,
    private auth: AuthService,
    private router: Router,
    private translate: TranslateService) { 
      
    }

  ngOnInit() {

    // INÍCIO - Tradução de conteúdo
    this.translate.stream('input.nome').subscribe(res => {
      this.inputNome = res;
    });
    this.translate.stream('input.descricao').subscribe(res => {
      this.inputDescricao = res;
    });
    this.translate.stream('input.grupo').subscribe(res => {
      this.inputGrupo = res;
    });
    this.translate.stream('placeholder.grupo').subscribe(res => {
      this.placeholderGrupo = res;
    });
    this.translate.stream('erro.nome').subscribe(res => {
      this.erroNome = res;
    });
    this.translate.stream('erro.pbix').subscribe(res => {
      this.erroPbix = res;
    });
    this.translate.stream('erro.grupo').subscribe(res => {
      this.erroGrupo = res;
    });
    this.translate.stream('botao.salvar').subscribe(res => {
      this.btnSalvar = res;
    });
    // FIM - Tradução de conteúdo

    this.idUsuarioAutorizador = this.auth.jwtPayload.id;

    this.route.params.subscribe(params => {
      this.editPainel = params;
      
    if(this.editPainel.idPainel){
      this.grupoService.getAllListaEdit(this.editPainel.idPainel).subscribe(result => {
        this.grupos = result;
        
        this.listaGrupoAnterior = result;
        this.gruposSelecionados = Object.assign([], result);

        this.grupos.forEach(val => {
          if(!val.selecionado) {
            this.gruposSelecionados.splice(this.gruposSelecionados.indexOf(val), 1);
          }
        }
        );
        
        // this.pageHeader = "Editar Painel"
        this.translate.stream('add-paineis.title2').subscribe(res => {
          this.pageHeader = res;
        });
        this.nomePainel = this.editPainel.dsNome;
        this.descricaoPainel = this.editPainel.dsPainel;
        this.urlPbix = this.editPainel.urlPbix;
      });
    }else{
      // this.pageHeader = "Cadastrar Painel"
      this.translate.stream('add-paineis.title1').subscribe(res => {
        this.pageHeader = res;
      });
      this.grupoService.getAll().subscribe(result => {
        this.grupos = result;
      });

    }

    });

  }

  savePainel(){
    this.cleanErrorsValidators();
    if(this.nomePainel && this.urlPbix){
    if(this.editPainel.idPainel){
      this.painel.idPainel = this.editPainel.idPainel;
      this.painel.dsNome = this.nomePainel;
      this.painel.dsPainel = this.descricaoPainel;
      this.painel.urlPbix = this.urlPbix;
      this.painel.idUsuarioAutorizador = this.idUsuarioAutorizador;
      this.painel.listaGrupos = this.gruposSelecionados;
      this.painel.listaGruposAnterior = this.grupos;
    }else{  
      this.painel = new Painel(this.idUsuarioAutorizador, this.nomePainel, this.descricaoPainel, this.urlPbix,this.gruposSelecionados);
    }
      this.painelService.addPainel(this.painel).subscribe(result => {
          this.showToast(this.statusSuccess, this.titleSuccess, this.contentSuccess);
          this.cleanComponents();
          this.router.navigate(["/list-paineis"]);
      },
      err => {
        this.showToast(this.statusFail, this.titleFail, this.contentFail);
      });
    }else{
      this.validationName();
      this.validationUrl();
    }
  }

  open(dialog: TemplateRef<any>) {
    this.dialogService.open(dialog, { context: 'dialog' });
  }

  cleanErrorsValidators(){
    this.showErrorName = false;
    this.showErrorUrl = false;
  }

  validationName(){
    if(!this.nomePainel){
      this.showErrorName = true;
    }
  }

  validationUrl(){
    if(!this.urlPbix){
      this.showErrorUrl = true;
    }
  }

  validationGrupo(){
    if(this.gruposSelecionados.length == 0){
      this.showErrorGrupo = true;
    }
  }

  cleanComponents(){
    this.nomePainel = "";
    this.descricaoPainel = "";
    this.urlPbix = "";
    this.gruposSelecionados = []
  }

  private showToast(type: NbComponentStatus, title: string, body: string) {
    const config = {
      status: type,
      destroyByClick: this.destroyByClick,
      duration: this.duration,
      hasIcon: this.hasIcon,
      position: this.position,
      preventDuplicates: this.preventDuplicates,
    };
    this.toastrService.show(
      body,
      title,
      config);  
  }
}


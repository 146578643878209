export class Painel{
    public idUsuarioAutorizador: number;
    public idPainel: number;
    public dsNome: string;    
    public dsPainel: string;
    public urlPbix: string;
    public listaGrupos: any[];
    public listaGruposAnterior: any[];

    constructor(idUsuarioAutorizador?:number, dsNome?: string, dsPainel?: string, urlPbix?: string, listaGrupos?: any[], listaGruposAnterior?: any[]){
        this.dsNome = dsNome;
        this.dsPainel = dsPainel;
        this.urlPbix = urlPbix;
        this.listaGrupos = listaGrupos;
        this.listaGruposAnterior = listaGruposAnterior;
        this.idUsuarioAutorizador = idUsuarioAutorizador;
    }
    
}
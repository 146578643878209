export class GrupoDTO {
    dsNome: String;

    constructor(
        dsNome: String
    ) {
        this.dsNome = dsNome;


    }
}
import {
  AfterContentChecked,
  AfterContentInit,
  Component,
  OnDestroy,
  OnInit
} from '@angular/core';

import { AuthService } from './auth.service';
import { MenuPermissoesService } from '../menuPermissoes.service';
import { NbMenuItem } from '@nebular/theme';
import { PainelService } from '../shared/services/painel.service';
import { TranslateService } from '@ngx-translate/core';

// import { PAINEL_ITEMS } from './pages-menu';

@Component({
  selector: 'ngx-pages',
  styleUrls: ['pages.component.scss'],
  template: `
    <ngx-one-column-layout>
      <nb-menu [items]="menuAdminService.items"></nb-menu>
      <nb-menu [items]="menu" style="border-top: 1px solid #edf1f7; margin-top:20px"></nb-menu> 
      <router-outlet></router-outlet>
    </ngx-one-column-layout>
  `
})
export class PagesComponent implements OnInit {
  constructor(
    private auth: AuthService,
    public menuAdminService: MenuPermissoesService,
    private painelService: PainelService,
    private translateService: TranslateService
  ) {}
  listMenu: NbMenuItem[] = [];
  PAINEL_ITEMS: NbMenuItem[] = [];
  MENU_ITEMS: NbMenuItem[] = [];
  menu: NbMenuItem[] = [];
  adminMenu: NbMenuItem[] = [];
  userID: number = 0;

  escondeMenu: boolean = true;

  ngOnInit() {
    this.userID = this.auth.jwtPayload ? this.auth.jwtPayload.id : 0;
    this.painelService.getMenuItens(this.userID).subscribe(result => {
      this.listMenu = result;
      if (this.auth.temPermissao('ROLE_CADASTRAR_USUARIO')) {
        this.escondeMenu = false;
        this.MENU_ITEMS = this.menuAdminService.items;
      }

      // this.auth.temPermissao("ROLE_CADASTRAR_USUARIO");

      //[
      //   {
      //     title: "Gerenciamento de Painéis",
      //     icon: "bar-chart-2-outline",
      //     children: [
      //       {
      //         title: "Cadastrar Painel",
      //         link: "/add-paineis"
      //       },
      //       {
      //         title: "Listar Painéis",
      //         link: "/list-paineis"
      //       }
      //     ],
      //   },
      //   {
      //     title: "Gerenciamento de Usuários",
      //     icon: "person-outline",
      //     children: [
      //       {
      //         title: "Cadastrar Usuario" ,
      //         link: "/add-users"
      //       },
      //       {
      //         title: "Listar Usuários",
      //         link: "/list-users"
      //       }
      //     ]
      //   },
      //   {
      //     title: "Gerenciamento de Grupos",
      //     icon: "swap-outline",
      //     children: [
      //       {
      //         title: "Listar Grupos",
      //         link: "/list-grupos"
      //       }
      //     ]
      //   }
      // ]

      this.PAINEL_ITEMS = [
        {
          title: 'Painéis',
          icon: 'pie-chart-outline',
          home: true,
          children: this.listMenu.sort(function(a,b) {
            return a.title.trim().toUpperCase() < b.title.trim().toUpperCase() ? -1 : a.title.trim().toUpperCase() > b.title.trim().toUpperCase() ? 1 : 0;
        })
        }
      ];
      this.adminMenu = this.MENU_ITEMS;
      this.menu = this.PAINEL_ITEMS;
      console.log( this.listMenu.sort(function(a,b) {
        return a.title.trim().toUpperCase() < b.title.trim().toUpperCase() ? -1 : a.title.trim().toUpperCase() > b.title.trim().toUpperCase() ? 1 : 0;
    }))
      

      // INÍCIO: Função tradução
      this.adminMenu.filter((elemento,index) => {
        this.translateService.stream('menu.'+ index).subscribe(res => {
          elemento.title = res;
          if (elemento.children.length > 0) {
            elemento.children.filter((elemento2, index2) => {
              this.translateService.stream('submenu.'+ elemento.title + index2).subscribe(res2 => {
                elemento2.title = res2 
              });
            })
          }
        });  
      })
      this.menu.filter((elemento,index) => {
        this.translateService.stream('painel.'+ index).subscribe(res => {
          elemento.title = res;
        });  
      })
      // FIM: Função tradução
    });
  }
}

import { Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { Router, RouterModule, ActivatedRoute } from '@angular/router';
import { NotAuthenticatedError } from './bpo-http-interceptor';
import { NbToastrService, NbGlobalPhysicalPosition, NbComponentStatus, NbToastRef } from '@nebular/theme';
import { ToasterConfig } from 'angular2-toaster';




@Injectable({
  providedIn: 'root'
})
export class ErrorHandlerService {
  config: ToasterConfig;

  index = 1;
  destroyByClick = true;
  duration = 3000;
  // title = 'OK!';
  // content = `Robô incluído com Sucesso`;
  hasIcon = true;
  position: NbGlobalPhysicalPosition = NbGlobalPhysicalPosition.TOP_LEFT;
  preventDuplicates = false;
  status: NbComponentStatus = 'danger';
  
  constructor(
    private toastrService: NbToastrService,
    private router: Router
    ) { }

  handle(errorResponse: any) {
    let msg: string;
    let tipo: string;

    if (typeof errorResponse === 'string') {
      msg = errorResponse;

    }

    else if (errorResponse instanceof NotAuthenticatedError) {
      tipo = 'Por favor, faça o login novamente';
      msg = 'Sua sessão expirou!';
      this.router.navigate(['/login']);

    }else if (errorResponse instanceof HttpErrorResponse
        && errorResponse.status >= 400 && errorResponse.status <= 499) {
          if (errorResponse.status == 404) {
            this.router.navigate(['/**']);
            return;
        }

        if(errorResponse.status === 403) {
          tipo = 'Não autorizado';
          msg = 'Você não tem permissão para executar essa ação';
        }
        let errors;
        tipo = 'Ocorreu um erro';
        msg = 'Houve um problema ao processar a sua solicitação';
  
        try {
          errors = errorResponse.error;
  
          msg = errors[0].mensagemUsuario;
        } catch (e) { }
        tipo = 'Ocorreu um erro';
        msg = 'Houve um problema ao processar a sua solicitação';

  
 

    } else {
      msg = 'Erro ao processar serviço remoto. Tente novamente.';

    }
    this.toastrService.show(
      tipo,
      msg,
      {
       status: 'danger',
      destroyByClick: this.destroyByClick,
      duration: this.duration,
      hasIcon: this.hasIcon,
      position: this.position,
      preventDuplicates: this.preventDuplicates,
      
      });  
  }

    
    
    }

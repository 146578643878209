export class ChangePassword{
    id:number;
    newPassword: string;
    actualPassword: string;

    constructor(id:number, newPassword: string, actualPassword:string){
        this.id = id;
        this.newPassword = newPassword;
        this.actualPassword = actualPassword;
    }
}
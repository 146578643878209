import { Component, OnInit, TemplateRef } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { NbDialogService } from '@nebular/theme';
import { DayWeek } from '../../enumerations/day-week';
import { TypeTrigger } from '../../enumerations/type-trigger';
import { Month } from '../../enumerations/month';
import { ToasterConfig } from 'angular2-toaster';
import {
  NbComponentStatus,
  NbGlobalLogicalPosition,
  NbGlobalPhysicalPosition,
  NbGlobalPosition,
  NbToastrService,
} from '@nebular/theme';
import { scheduled } from 'rxjs';
import { Usuario } from '../../shared/classModels/usuario';
import { Painel } from '../../shared/classModels/painel';
import { GrupoService } from '../../shared/services/grupo.service';
import { Grupo } from '../../shared/classModels/grupo';
import { UsuarioService } from '../../shared/services/usuario.service';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'ngx-add-user',
  templateUrl: './add-user.component.html',
  styleUrls: ['./add-user.component.scss']
})
export class AddUserComponent implements OnInit {

  nomeUsuario:string = '';
  pageHeader: string = '';
  placeholderVariavel = 'Teste123';
  setCamposObrigatorios: boolean = true;
  cpfUsuario:string = '';
  isActive:boolean = false;
  usuarioLogin: string = '';
  usuarioSenha:string = '';
  usuario:Usuario = new Usuario();
  grupos: any[] = [];
  grupoSelecionado: any = null;
  idUsuario = null;
  usuarioExists: any = null;

  showErrorNome: boolean;
  showErrorCpf: boolean;
  showErrorLogin: boolean;
  showErrorSenha: boolean;
  showErrorGrupo: boolean;

  config: ToasterConfig;

  index = 1;
  destroyByClick = true;
  duration = 5000;
  hasIcon = true;
  position: NbGlobalPosition = NbGlobalPhysicalPosition.TOP_RIGHT;
  preventDuplicates = false;
  
  statusSuccess: NbComponentStatus = 'success';
  statusFail: NbComponentStatus = 'danger';
  toastOptions = {
    titleSuccess:   'OK!', 
    titleFail:      'ERRO!', 
    contentSuccess: 'Usuário incluído com Sucesso',
    contentFail:    'Usuário não foi incluído, tente novamente mais tarde',
    contentSuccessEdit: 'Usuário alterado com Sucesso',
    contentFailEdit:    'Usuário não pôde ser alterado. Tente novamente mais tarde.',
    contentFailLoginExists:  'Este login de usuário já existe.'
  };

  inputNome: string = '';
  inputSenha: string = '';
  inputLogin: string = '';
  btnSalvar: string = '';
  erroNome: string = '';
  erroLogin: string = '';
  erroSenha: string = '';
  erroGrupo: string = '';

  constructor(
    private dialogService: NbDialogService,
    private toastrService: NbToastrService,
    private grupoService: GrupoService,
    private usuarioService: UsuarioService,
    private route: ActivatedRoute,
    private router: Router,
    private translate: TranslateService) { 
    };

  ngOnInit() {
    // INÍCIO - Tradução de conteúdo
    this.translate.stream('input.nome').subscribe(res => {
      this.inputNome = res;
    });
    this.translate.stream('input.senha').subscribe(res => {
      this.inputSenha = res;
    });
    this.translate.stream('input.login').subscribe(res => {
      this.inputLogin = res;
    });
    this.translate.stream('botao.salvar').subscribe(res => {
      this.btnSalvar = res;
    });
    this.translate.stream('erro.nome').subscribe(res => {
      this.erroNome = res;
    });
    this.translate.stream('erro.login').subscribe(res => {
      this.erroLogin = res;
    });
    this.translate.stream('erro.senha').subscribe(res => {
      this.erroSenha = res;
    });
    this.translate.stream('erro.grupoSingular').subscribe(res => {
      this.erroGrupo = res;
    });
    // FIM - Tradução de conteúdo
    this.route.params.subscribe(params => {
      this.idUsuario = params.idUsuario;
      if(this.idUsuario){
        this.usuarioService.getUserById(this.idUsuario).subscribe(result =>{
          this.nomeUsuario = result.nome;
          this.usuarioSenha = "";
          this.cpfUsuario = result.cpf;
          this.usuarioLogin = result.login;
          this.grupoSelecionado = result.grupo;
          // this.pageHeader = "Editar Usuário"
          this.translate.stream('add-users.title2').subscribe(res => {
            this.pageHeader = res;
          });
          this.placeholderVariavel = result.grupo.dsNome;
          this.setCamposObrigatorios = false;
        });
      }
      else {
        this.setCamposObrigatorios = true;

        // this.pageHeader = "Adicionar Usuário"
        this.translate.stream('add-users.title1').subscribe(res => {
          this.pageHeader = res;
        });
        this.placeholderVariavel = ""
      }    
    });
    this.grupoService.getAll().subscribe(result => {
      this.grupos = result;
    });    
  }

  checkLoginExists(){
    this.usuarioService.getUsuariosByLogin(this.usuarioLogin).subscribe(result => {
      this.usuarioExists = result;
    }, err => {
      this.usuarioExists = null;}
    );

    setTimeout(
      () => {
        this.saveUser();
      },
    500)
  }

  saveUser(){
      if(this.idUsuario){
        if(this.nomeUsuario /*&& this.cpfUsuario*/ 
          && this.usuarioLogin && this.grupoSelecionado){
        this.usuario = new Usuario(this.idUsuario, this.nomeUsuario, this.cpfUsuario?this.cpfUsuario:null, 
        this.usuarioLogin, this.usuarioSenha, this.grupoSelecionado);
        this.usuarioService.addUsuario(this.usuario).subscribe(result =>{
          this.showToast(this.statusSuccess, this.toastOptions.titleSuccess, this.toastOptions.contentSuccessEdit);
          this.cleanComponents();
          this.router.navigate(["/list-users"]);
        },
        err => {
          this.showToast(this.statusFail, this.toastOptions.titleFail, this.toastOptions.contentFailEdit);
        });
        }else{
          // this.validationCpf();
          this.validationLogin();
          this.validationNome();
          this.validationSenha();
          this.validationGrupo();
        }
      }else{
        if(this.usuarioExists){
          this.showToast(this.statusFail, this.toastOptions.titleFail, this.toastOptions.contentFailLoginExists);
        }
        else{
          if(this.nomeUsuario
            && this.usuarioLogin && this.usuarioSenha && this.grupoSelecionado){  
              this.usuario = new Usuario(null, this.nomeUsuario, this.cpfUsuario?this.cpfUsuario:null, 
              this.usuarioLogin, this.usuarioSenha, this.grupoSelecionado);
              this.usuarioService.addUsuario(this.usuario).subscribe(result =>{
                this.showToast(this.statusSuccess, this.toastOptions.titleSuccess, this.toastOptions.contentSuccess);
                this.cleanComponents();
                this.router.navigate(["/list-users"]);
              },
              err => {
                this.showToast(this.statusFail, this.toastOptions.titleFail, this.toastOptions.contentFail);
              });
            }else{
              // this.validationCpf();
              this.validationLogin();
              this.validationNome();
              this.validationSenha();
              this.validationGrupo();
            }
          }
      }
  }

  open(dialog: TemplateRef<any>) {
    this.dialogService.open(dialog, { context: 'dialog' });
  }

  cleanErrorsValidators(){
    this.showErrorNome = false; 
    this.showErrorCpf = false;
    this.showErrorLogin = false;
    this.showErrorSenha = false;
    this.showErrorGrupo = false;
  }

  validationNome(){
    if(!this.nomeUsuario){
      this.showErrorNome= true;
    }
  }

  
  validationSenha(){
    if(!this.usuarioSenha){
      this.showErrorSenha= true;
    }
  }

  validationLogin(){
    if(!this.usuarioLogin){
      this.showErrorLogin = true;
    }
  }

  validationGrupo(){
    if(this.grupoSelecionado == null){
      this.showErrorGrupo = true;
    }
  }

  cleanComponents(){
    this.nomeUsuario = "";
    this.usuarioSenha = "";
    this.cpfUsuario = "";
    this.usuarioLogin = "";
  }

  private showToast(type: NbComponentStatus, title: string, body: string) {
    const config = {
      status: type,
      destroyByClick: this.destroyByClick,
      duration: this.duration,
      hasIcon: this.hasIcon,
      position: this.position,
      preventDuplicates: this.preventDuplicates,
    };
    this.toastrService.show(
      body,
      title,
      config);  
  }
}


import { HttpClient, HttpHeaders, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { Observable, of } from 'rxjs';

import { catchError, map, tap } from 'rxjs/operators';

import { AppConstants} from '../constants/shared.constants';
import { Injectable } from '@angular/core';
import { NbMenuItem } from '@nebular/theme';
import { Painel } from '../classModels/painel';


const basePathEndpoint = '/api/painel';

@Injectable({
  providedIn: 'root'
})

export class PainelService {

  _baseHttpHeader : HttpHeaders;
  _baseURL : string;
  _pathEndpoint : string;

  constructor(private http: HttpClient) { 
    this._baseURL = AppConstants.baseURL;
    this._baseHttpHeader = AppConstants.baseHttpOptions;
    this._pathEndpoint = this._baseURL + basePathEndpoint;
  }


  getMenuItens(userId: number):Observable<any> {
    let endpoint = `${this._pathEndpoint}/menu/${userId.toString()}`;
    return this.http.get<NbMenuItem[]>(endpoint, {headers: this._baseHttpHeader});    
  }
  
  getPainelLink(userId: number, painelId: string):Observable<any> {
    let endpoint = `${this._pathEndpoint}/embedded/${userId.toString()}/${painelId}`;
    return this.http.get<any>(endpoint, {headers: this._baseHttpHeader});    
  }

  addPainel(painel: Painel): Observable<any>{
    let endpoint = `${this._pathEndpoint}/add-painel`;
    var json = JSON.stringify(painel);
    return this.http.post(endpoint,json, {headers: this._baseHttpHeader});    
  }

  getAll():Observable<any> {
    let endpoint = `${this._pathEndpoint}/all`;
    return this.http.get<any>(endpoint, {headers: this._baseHttpHeader});    
  }

  updatePainelStatus(painelId: number): Observable<any>{
    let endpoint = `${this._pathEndpoint}/change-status/${painelId}`;
    return this.http.put(endpoint, {headers: this._baseHttpHeader});    
  }

  deletePainel(painelId: number): Observable<any>{
    let endpoint = `${this._pathEndpoint}/delete-painel/${painelId}`;
    return this.http.delete(endpoint, {headers: this._baseHttpHeader});    
  }


}
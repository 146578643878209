import { HttpHeaders } from '@angular/common/http';
import { environment } from '../../../environments/environment';

export class AppConstants {
    
    public static get baseURL(): string { return environment.hosts.bipo_backend_rest }

    public static get baseHttpOptions() : HttpHeaders {
        return new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': 'No Auth'
        });
    }
    
}
import { BpoHttpInterceptor } from './pages/bpo-http-interceptor';
import { AuthService } from './pages/auth.service';
import { PagesModule } from './pages/pages.module';

import { HashLocationStrategy, LocationStrategy } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { CoreModule } from './@core/core.module';
import { ThemeModule } from './@theme/theme.module';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { NbEvaIconsModule } from '@nebular/eva-icons';
import {
  NbChatModule,
  NbDatepickerModule,
  NbDialogModule,
  NbMenuModule,
  NbSidebarModule,
  NbToastrModule,
  NbWindowModule,
  NbCardModule,
} from '@nebular/theme';
import { FormsModule } from '@angular/forms';
import { JwtModule, JwtHelperService } from '@auth0/angular-jwt';
import { AuthGuard } from './pages/auth.guard';
import { SafePipe } from './pipes/custom.pipe';
import { MenuPermissoesService } from './menuPermissoes.service';
import { environment } from '../environments/environment';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';


export function tokenGetter(): string {
  return localStorage.getItem('token');
}

// const globalSettings: RecaptchaSettings = { siteKey: '6LceJNwZAAAAAHBvFfsEFh576G6XpigabdlGOWGp' };


@NgModule({
  declarations: [
  AppComponent
  ],
  
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    AppRoutingModule,
    NbCardModule,
    NbEvaIconsModule,
    FormsModule,
    PagesModule,

    ThemeModule.forRoot(),

    NbSidebarModule.forRoot(),
    NbMenuModule.forRoot(),
    NbDatepickerModule.forRoot(),
    NbDialogModule.forRoot(),
    NbWindowModule.forRoot(),
    NbToastrModule.forRoot(),
    NbChatModule.forRoot({
      messageGoogleMapKey: 'AIzaSyA_wNuCzia92MAmdLRzmqitRGvCF7wCZPY',
    }),
    CoreModule.forRoot(),

    JwtModule.forRoot({
      config: {
        tokenGetter: tokenGetter,
        whitelistedDomains: [environment.hosts.bipo_whitelisted_domains],
        blacklistedRoutes: [environment.hosts.bipo_blacklisted_routes]
      }
    }),

    TranslateModule.forRoot({
      loader: {
          provide: TranslateLoader,
          useFactory: (HttpLoaderFactory),
          deps: [HttpClient]
      }
    })
  ], 
  providers: [
    AuthService,
    JwtHelperService,
    MenuPermissoesService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: BpoHttpInterceptor,
      multi: true
  },
  AuthGuard,
  { 
    provide: LocationStrategy, useClass: HashLocationStrategy
  }
 ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.txt');
}

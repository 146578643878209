import { HttpClient, HttpHeaders, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { Observable, of } from 'rxjs';

import { catchError, map, tap } from 'rxjs/operators';

import { AppConstants} from '../constants/shared.constants';
import { Injectable } from '@angular/core';
import { NbMenuItem } from '@nebular/theme';
import { Painel } from '../classModels/painel';
import { GrupoDTO } from '../../pages/list-grupo/grupoDTO';


const basePathEndpoint = '/api/grupo';

@Injectable({
  providedIn: 'root'
})

export class GrupoService {

  _baseHttpHeader : HttpHeaders;
  _baseURL : string;
  _pathEndpoint : string;

  constructor(private http: HttpClient) { 
    this._baseURL = AppConstants.baseURL;
    this._baseHttpHeader = AppConstants.baseHttpOptions;
    this._pathEndpoint = this._baseURL + basePathEndpoint;
  }

  saveGrupo(grupoDescricao: GrupoDTO): Observable<any>{
    var json = JSON.stringify(grupoDescricao);
    let endpoint = `${this._pathEndpoint}/save`;
    var request = this.http.post<any>(endpoint, json, {headers: this._baseHttpHeader});
    return request; 
  }

  getAllListaEdit(idPainel: number):Observable<any> {
    let endpoint = `${this._pathEndpoint}/all-edit-list/${idPainel}`;
    return this.http.get<any>(endpoint, {headers: this._baseHttpHeader});    
  }

  getAll():Observable<any> {
    let endpoint = `${this._pathEndpoint}/all`;
    return this.http.get<any>(endpoint, {headers: this._baseHttpHeader});    
  }

  getAllDisponiveis(idPainel: number):Observable<any> {
    let endpoint = `${this._pathEndpoint}/grupos-disponiveis/${idPainel}`;
    return this.http.get<any>(endpoint, {headers: this._baseHttpHeader});    
  }

  updateGrupoStatus(grupoId: number): Observable<any>{
    let endpoint = `${this._pathEndpoint}/change-status/${grupoId}`;
    return this.http.put(endpoint, {headers: this._baseHttpHeader});    
  }

  updateDescricaoGrupo(grupoId: number, grupoDescricao: GrupoDTO): Observable<any>{
    let endpoint = `${this._pathEndpoint}/update-descricao/${grupoId}`;
    return this.http.put(endpoint, grupoDescricao, {headers: this._baseHttpHeader});    
  }

  deleteGrupo(grupoId: number): Observable<any>{
    let endpoint = `${this._pathEndpoint}/delete-grupo/${grupoId}`;
    return this.http.delete(endpoint, {headers: this._baseHttpHeader});    
  }

 

  


}
import { Component, OnInit } from '@angular/core';
import { GrupoDTO } from '../list-grupo/grupoDTO';
import { Grupo } from '../../shared/classModels/grupo';
import { GrupoService } from '../../shared/services/grupo.service';
import { ActivatedRoute, Router } from '@angular/router';
import { UsuarioService } from '../../shared/services/usuario.service';
import { TranslateService } from '@ngx-translate/core';

import {
  NbComponentStatus,
  NbGlobalLogicalPosition,
  NbGlobalPhysicalPosition,
  NbGlobalPosition,
  NbToastrService,
} from '@nebular/theme';

@Component({
  selector: 'ngx-cad-grupo',
  templateUrl: './cad-grupo.component.html',
  styleUrls: ['./cad-grupo.component.scss']
})
export class CadGrupoComponent implements OnInit {

  grupoHeader = '';
  botaoConfirmar = '';
  nomeGrupo:String = '';
  grupo:any;
  gruposExistentes: any[] = [];

  showErrorNomeGrupo : boolean;
  showErrorGrupoExistente : boolean;
  idUsuario: any;
  idGrupo: any;
  dsNome: any;

  position: NbGlobalPosition = NbGlobalPhysicalPosition.TOP_RIGHT;
  preventDuplicates = false;
  destroyByClick = true;
  hasIcon = true;
  duration = 5000;
  titleFail = 'ERRO!';
  titleSuccess = 'OK!';
  contentSuccess = `Grupo incluído com Sucesso`;
  contentSuccessEdit = `Grupo alterado com Sucesso`;
  contentFail = `Já existe um grupo com este nome.`;
  statusFail: NbComponentStatus = 'danger';
  statusSuccess: NbComponentStatus = 'success';

  btnSalvar: string = '';
  inputNome: string = '';
  erroNome: string = '';

constructor(
    private usuarioService: UsuarioService,
    private toastrService: NbToastrService,
    private grupoService: GrupoService,
    private route: ActivatedRoute,
    private router: Router,
    private translate: TranslateService
 ) {};

  ngOnInit() {
    // INÍCIO - Tradução de conteúdo
    this.translate.stream('input.nomeGrupo').subscribe(res => {
      this.inputNome = res;
    });
    this.translate.stream('botao.salvar').subscribe(res => {
      this.btnSalvar = res;
    });
    this.translate.stream('erro.nomeGrupo').subscribe(res => {
      this.erroNome = res;
    });
    // FIM - Tradução de conteúdo

    this.route.params.subscribe(params => {
      this.idGrupo = params.idGrupo;
      this.dsNome = params.dsNome;
      this.nomeGrupo = this.dsNome;
      if (this.idGrupo) {
        // this.grupoHeader = "Editar Grupo"
        this.translate.stream('cad-grupo.title2').subscribe(res => {
          this.grupoHeader = res;
        });
      }
      else {
        // this.grupoHeader = "Cadastrar Grupo"
        this.translate.stream('cad-grupo.title1').subscribe(res => {
          this.grupoHeader = res;
        });
      }
    });

    this.grupoService.getAll().subscribe(result => {
      this.gruposExistentes = result;
    });

  }


  saveGrupo(dsNome:String){
    this.cleanErrorsValidators();

    this.gruposExistentes.forEach(element => {
      if (element.dsNome === this.nomeGrupo){
        this.validationGrupoExistente();
      }
    });

    if(this.nomeGrupo){
      if(!this.showErrorGrupoExistente){
        if (this.idGrupo) {
          this.grupo = new GrupoDTO(this.nomeGrupo);
          this.grupoService.updateDescricaoGrupo(this.idGrupo, this.grupo).subscribe();
          setTimeout(() => {
          this.showToast(this.statusSuccess, this.titleSuccess, this.contentSuccessEdit);
          this.cleanComponents();
          this.router.navigate(["/list-grupos"]);
          },500);
        }
        else {
          this.grupo = new GrupoDTO(dsNome);
          this.grupoService.saveGrupo(this.grupo).subscribe();
          setTimeout(() => {
            this.showToast(this.statusSuccess, this.titleSuccess, this.contentSuccess);
            this.cleanComponents();
            this.router.navigate(["/list-grupos"]);
            },500);
        }
      }else{
        this.showToast(this.statusFail, this.titleFail, this.contentFail);
        return;
      }
    }else{
    this.validationNomeGrupo();
    }
  }

  validationNomeGrupo(){
      this.showErrorNomeGrupo= true;
  }

  validationGrupoExistente(){
    this.showErrorGrupoExistente= true;
  }

  cleanErrorsValidators(){
    this.showErrorNomeGrupo = false;
    this.showErrorGrupoExistente = false;
  } 

  cleanComponents(){
    this.nomeGrupo = "";
  }

  private showToast(type: NbComponentStatus, title: string, body: string) {
    const config = {
      status: type,
      destroyByClick: this.destroyByClick,
      duration: this.duration,
      hasIcon: this.hasIcon,
      position: this.position,
      preventDuplicates: this.preventDuplicates,
    };
    this.toastrService.show(
      body,
      title,
      config);  
  }

}


import { Component, OnInit, TemplateRef } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { NbDialogService } from '@nebular/theme';
import { DayWeek } from '../../enumerations/day-week';
import { TypeTrigger } from '../../enumerations/type-trigger';
import { Month } from '../../enumerations/month';
import { HttpClient } from '@angular/common/http';
import { PainelService } from '../../shared/services/painel.service';
import { Router, ActivatedRoute } from '@angular/router';
import { Painel } from '../../shared/classModels/painel';
import { ToasterConfig } from 'angular2-toaster';
import { GrupoService } from '../../shared/services/grupo.service';
import { UsuarioService } from '../../shared/services/usuario.service';
import { Grupo } from '../../shared/classModels/grupo';
import { GrupoDTO } from './grupoDTO';
import { TranslateService } from '@ngx-translate/core';

import {
  NbComponentStatus,
  NbGlobalLogicalPosition,
  NbGlobalPhysicalPosition,
  NbGlobalPosition,
  NbToastrService,
} from '@nebular/theme';

@Component({
  selector: 'ngx-list-grupo',
  templateUrl: './list-grupo.component.html',
  styleUrls: ['./list-grupo.component.scss']
})
export class ListGrupoComponent implements OnInit {
grupo: any;
  public grupos: any[] = [];
  public idGrupoDelete: number;
  public idGrupoAtualizado: number;
  public indexDeletado: number;
  public nomeGrupo = '';
  novoNomeGrupo: String;
  public usuariosAssociados: any[] = [];
  public showErrorUsuariosAssociados: boolean = false;

  position: NbGlobalPosition = NbGlobalPhysicalPosition.TOP_RIGHT;
  preventDuplicates = false;
  destroyByClick = true;
  hasIcon = true;
  duration = 5000;
  titleFail = 'ERRO!';
  contentFail = `O grupo contém usuários associados.`;
  statusFail: NbComponentStatus = 'danger';

  pageHeader: string = '';
  inputNome: string = '';
  tableNome: string = '';
  tableAcao: string = '';
  tooltipExcluir: string = '';
  tooltipRenomear: string = '';
  titleDialog: string = '';
  textDialog: string = '';
  btnContinuar: string = '';
  btnFechar: string = '';

  constructor(
    private dialogService: NbDialogService,
    private toastrService: NbToastrService,
    private httpClient: HttpClient,
    private grupoService: GrupoService,
    private usuarioService: UsuarioService,
    private router: Router,
    private translate: TranslateService
    ) {}

  ngOnInit() {
    // INÍCIO - Tradução de conteúdo
    this.translate.stream('list-grupo.title').subscribe(res => {
      this.pageHeader = res;
    });
    this.translate.stream('input.nome').subscribe(res => {
      this.inputNome = res;
    });
    this.translate.stream('table.nomeGrupo').subscribe(res => {
      this.tableNome = res;
    });
    this.translate.stream('table.acao').subscribe(res => {
      this.tableAcao = res;
    });
    this.translate.stream('tooltip.excluir').subscribe(res => {
      this.tooltipExcluir = res;
    });
    this.translate.stream('tooltip.renomear').subscribe(res => {
      this.tooltipRenomear = res;
    });
    this.translate.stream('dialog.titleRemoverGrupo').subscribe(res => {
      this.titleDialog = res;
    });
    this.translate.stream('dialog.textRemoverGrupo').subscribe(res => {
      this.textDialog = res;
    });
    this.translate.stream('botao.fechar').subscribe(res => {
      this.btnFechar = res;
    });
    this.translate.stream('botao.continuar').subscribe(res => {
      this.btnContinuar = res;
    });
    // FIM - Tradução de conteúdo

    this.grupoService.getAll().subscribe(result => {   
      this.grupos = result;
    });
  }

  onChangeStatus(newValue, idGrupo: number){
    this.grupoService.updateGrupoStatus(idGrupo).subscribe()
  }

  updateDescricaoGrupo(idGrupo : number, descricaoGrupo : String){
    this.grupo = new GrupoDTO(descricaoGrupo);
    this.grupoService.updateDescricaoGrupo(idGrupo, this.grupo).subscribe();
    const grupoEditado = this.grupos.findIndex(a => a.idGrupo === idGrupo );
    this.grupos[grupoEditado].dsNome = descricaoGrupo;
  }


  deleteGrupo(){
    setTimeout(() => {
    if(this.usuariosAssociados.length > 0){
      this.showToast(this.statusFail, this.titleFail, this.contentFail);
    }else{
      this.grupoService.deleteGrupo(this.idGrupoDelete).subscribe();
      this.grupos.splice(this.indexDeletado, 1);
    }
  }, 1000)
  }

  openEdit(grupo: Grupo){
    this.router.navigate(["/cad-grupos", grupo]); 
  }

  
  openModalConfirmation(dialog: TemplateRef<any>, idPainel: number, i:any) {
    this.idGrupoDelete = idPainel;
    this.usuarioService.getUsuariosByGrupo(this.idGrupoDelete).subscribe(result => {
      this.usuariosAssociados = result;
    });

    this.indexDeletado = i;
    this.dialogService.open(dialog, { context: 'dialog' });    
  } 

  open(dialog: TemplateRef<any>) {
    // this.dialogService.open(dialog, { context: 'dialog' });
  }

    
  openUpdateGrupo(dialog: TemplateRef<any>, idPainel: number) {
    this.idGrupoAtualizado = idPainel;
    this.dialogService.open(dialog, { context: 'dialog' });    
  }

  cleanValidationErrors(){
    this.showErrorUsuariosAssociados = false;
  }

  validationUsuariosAssociados(){
    this.showErrorUsuariosAssociados = true;
  }


  private showToast(type: NbComponentStatus, title: string, body: string) {
    const config = {
      status: type,
      destroyByClick: this.destroyByClick,
      duration: this.duration,
      hasIcon: this.hasIcon,
      position: this.position,
      preventDuplicates: this.preventDuplicates,
    };
    this.toastrService.show(
      body,
      title,
      config);  
  }


}
